<template>
    <MainPage/>
</template>

<script>
import MainPage from "./components/MainPage";

export default {
    name: 'App',
    components: {
      MainPage
    },
}
</script>

<style>
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url('./styles/fonts/Roboto/Roboto-Regular.ttf') format("truetype");
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url('./styles/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
}
html, body, #app {
    height: 100%;
    background-color: #9C949C;
    font-family: 'Roboto', sans-serif;
}
</style>
